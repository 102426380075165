import React from 'react';
import Layout from '../components/Layout';
import { useSiteMetadata } from '../hooks';
import MTGC from '../components/MTGC';
import {GC_MIGRATION} from '../constants/services-names';
import {graphql, useStaticQuery} from "gatsby";

const MigrationToGoogleCloudTemplate = ({ location }) => {
    const { subtitle: siteSubtitle } = useSiteMetadata();
    const { bg } = useStaticQuery(graphql`
        query {
            bg: allFile(filter: { sourceInstanceName: { eq: "preview" } name: { eq: "migration" } }) {
                nodes {
                    name
                    publicURL
                }
            }
        }
    `);

    return (
        <Layout
            title={GC_MIGRATION}
            description={siteSubtitle}
            location={location}
            socialImage={bg.nodes[0].publicURL}
        >
            <MTGC/>
        </Layout>
    );
};

export default MigrationToGoogleCloudTemplate;
